import {
  ChartBarIcon,
  CurrencyDollarIcon,
  DeviceMobileIcon,
  LogoutIcon,
  PhotographIcon,
  QrcodeIcon,
  TicketIcon,
  UsersIcon,
  PlayIcon,
  HomeIcon,
  LockClosedIcon,
  OfficeBuildingIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { useContext, useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../common/AuthProvider";
import Dashboard from "../../common/Dashboard";
import { MenuItemProps } from "../../common/MenuItem";
import useMerchantTutorial from "../../hooks/merchant-tutorial.hook";
import useAuthContext from "../../hooks/auth-context.hook";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../common/components/ui/dropdown-menu";

export default function MerchantDashboard() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthContext();
  const { merchant, user } = auth.getMerchantAndUser();

  const { useMerchantTutorialQuery } = useMerchantTutorial();
  const { data: tutorialState } = useMerchantTutorialQuery(merchant.id);

  useEffect(() => {
    if (tutorialState && !tutorialState.completed) {
      navigate("/tutorial");
    }
  }, [navigate, tutorialState]);

  const menuItems: MenuItemProps[] = useMemo(() => {
    const items = [
      {
        picked: location.pathname.includes("profile"),
        label: "Storefront Profile",
        href: "/dashboard/profile",
        icon: <HomeIcon className="h-4 w-4" />,
      },
      {
        picked: location.pathname.includes("qbons"),
        label: "Qbons",
        icon:
          merchant.status === "SUBSCRIBED" ? (
            <TicketIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" color={"#fb3310"} />
          ),
        href:
          merchant.status === "SUBSCRIBED"
            ? "/dashboard/qbons"
            : "/dashboard/profile",
      },
      {
        picked: location.pathname.includes("giveaways"),
        label: "Giveaways",
        href:
          merchant.status === "SUBSCRIBED"
            ? "/dashboard/giveaways"
            : "/dashboard/profile",
        icon:
          merchant.status === "SUBSCRIBED" ? (
            <PlayIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" color={"#fb3310"} />
          ),
      },
      {
        picked: location.pathname.includes("content"),
        label: "Content",
        icon:
          merchant.status === "SUBSCRIBED" ? (
            <PhotographIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" color={"#fb3310"} />
          ),
        href:
          merchant.status === "SUBSCRIBED"
            ? "/dashboard/content"
            : "/dashboard/profile",
      },
      {
        picked: location.pathname.includes("devices"),
        label: "Devices",
        icon: <QrcodeIcon className="h-4 w-4" />,
        href: "/dashboard/devices",
      },
      {
        picked: location.pathname.includes("metrics"),
        label: "Metrics",
        href:
          merchant.status === "SUBSCRIBED"
            ? "/dashboard/metrics"
            : "/dashboard/profile",
        icon:
          merchant.status === "SUBSCRIBED" ? (
            <ChartBarIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" color={"#fb3310"} />
          ),
      },
      {
        picked: location.pathname.includes("scanner"),
        label: "Scanner",
        icon:
          merchant.status === "SUBSCRIBED" ? (
            <DeviceMobileIcon className="h-4 w-4" />
          ) : (
            <LockClosedIcon className="h-4 w-4" color={"#fb3310"} />
          ),
        href:
          merchant.status === "SUBSCRIBED"
            ? "/dashboard/scanner"
            : "/dashboard/profile",
      },
      {
        picked: location.pathname.includes("merchant-users"),
        label: "Users",
        icon: <UsersIcon className="h-4 w-4" />,
        href: "/dashboard/merchant-users",
      },
      {
        picked: location.pathname.includes("subscription"),
        label: "Subscription",
        icon: <CurrencyDollarIcon className="h-4 w-4" />,
        href: "/dashboard/subscription",
      }
    ];

    /**
    // Only show subscription menu item if user is not a general user
    if (user.type !== 'GENERAL') {
      items.push({
        picked: location.pathname.includes("subscription"),
        label: "Subscription",
        icon: <CurrencyDollarIcon className="h-4 w-4" />,
        href: "/dashboard/subscription",
      });
    }
       */

    return items;
  }, [location.pathname, merchant.status, user.type]);

  if (!authContext?.merchant) {
    navigate("/home");
    return <></>;
  }

  const logo = (
    <>
      <div className="flex flex-col items-center h-[115px]">
        <span className="text-black text-xl mx-2 font-semibold py-2 m-auto">
          <img
            className="m-auto text-center font-bold h-[50px]"
            src={require("../../assets/qbon_long_logo.png")}
            alt="qbon-logo-dashboard"
          />
        </span>
      </div>
    </>
  );

  return (
    <>
      <Dashboard
        menuItems={menuItems}
        logo={logo}
        barItems={
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className={
                    "flex hover:bg-zinc-100 cursor-pointer rounded-full p-1"
                  }
                >
                  <div className="m-auto pr-2 font-bold text-gray-700 text-sm hidden sm:block">
                    {merchant.name}
                  </div>
                  <div>
                    <img
                      src={
                        authContext.merchant.logoImageUrl ||
                        "https://prod-werehere-images.s3.amazonaws.com/QBON.png"
                      }
                      height={35}
                      width={35}
                      className="rounded-full"
                      alt="logo"
                    />
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  onClick={() => navigate("/dashboard/invites")}
                >
                  <div className={"flex cursor-pointer"}>
                    <MailIcon className="mr-2 h-4 w-4" />
                    My Invites
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => authContext?.exitMerchant()}>
                  <div className={"flex cursor-pointer  "}>
                    <OfficeBuildingIcon className="mr-2 h-4 w-4" />
                    Change Merchant
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => authContext?.logOut()}>
                  <div className={"flex cursor-pointer"}>
                    <LogoutIcon className="mr-2 h-4 w-4" />
                    Log Out
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        }
      >
        <Outlet />
      </Dashboard>
    </>
  );
}
